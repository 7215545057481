<app-logo variant="block" [clickable]="true" [spaced]="true"></app-logo>
<div (click)="openSearchDashboard()" class="search-area cursor-pointer">
    <fas i="search" class="icon"></fas>
    <div>Search</div>
</div>
<nav class="facility-select-wrapper" *ngIf="hasMultipleFacilities">
    <app-facility-select [style.opacity]="(fc.isObservedOpenly$ | async) ? null : .5"></app-facility-select>
</nav>
<div class="side-nav-wrapper">
    <nav class="side-nav" [@menuPassive]="userActions.open">
        <ul>
            <ng-template #navItem let-item="item">
                <li [ngClass]="{'open': item.open}">
                    {{ item.state }}
                    <ng-template #label let-item="item">
                        <fad i="{{ item.icon }}" class="icon"></fad>
                        <span class="caption">{{ item.name }}</span>
                    </ng-template>
                    <ng-container *ngIf="item.children">
                        <a (click)="item.toggleOpen()"
                           [attr.data-test-id]="item.testId">
                            <ng-container *ngTemplateOutlet="label; context: {item: item}"></ng-container>
                            <fal i="plus" class="chevron"></fal>
                        </a>
                        <ul *ngIf="item.children" [@menuOpen]="item.open">
                            <li *ngFor="let subItem of item.children">
                                <a [routerLink]="subItem.link"
                                   [queryParams]="subItem.queryParams"
                                   [routerLinkActive]="subItem.link ? 'active' : ''"
                                   [routerLinkActiveOptions]="{exact: !!subItem.exactMatch}"
                                   [attr.data-test-id]="item.testId">
                                    <ng-container *ngTemplateOutlet="label; context: {item: subItem}"></ng-container>
                                </a>
                            </li>
                        </ul>
                    </ng-container>
                    <a *ngIf="!item.children"
                       [routerLink]="item.link"
                       [queryParams]="item.queryParams"
                       [routerLinkActive]="item.link ? 'active' : ''"
                       [routerLinkActiveOptions]="{exact: false}"
                       [attr.data-test-id]="item.testId">
                        <ng-container *ngTemplateOutlet="label; context: {item: item}"></ng-container>
                    </a>
                </li>
            </ng-template>
            <ng-container *ngFor="let item of facMenuItems">
                <ng-container *ngTemplateOutlet="navItem; context: {item: item}"></ng-container>
            </ng-container>
        </ul>
    </nav>
</div>
<div class="side-nav-wrapper fix">
    <nav class="side-nav" [@menuPassive]="userActions.open">
        <ul>
            <ng-container *ngFor="let item of otherMenuItems">
                <ng-container *ngTemplateOutlet="navItem; context: {item: item}"></ng-container>
            </ng-container>
            <li>
                <a href="mailto:support@washsense.com">
                    <fad i="question" class="icon"></fad>
                    <span class="caption">Contact Us</span>
                </a>
            </li>
        </ul>
    </nav>
</div>
<div class="bottom-section" [@menuAccented]="userActions.open" *ngIf="user && user.id">
    <nav class="user-nav d-flex">
        <div class="face flex-grow-1" (click)="onUserClick()" id="userStatusTrigger">
            <app-user-icon [user]="user" classList="mr-2" [hasTooltip]="false"></app-user-icon>
            <span class="caption">{{ user | humanName:'firstNameFirst' }}</span>
            <fal *ngIf="!unreadCount; else unreadMarkers" i="chevron-right" class="chevron"></fal>
            <ng-template #unreadMarkers>
                <span class="bubble"
                      [@pulse]="unreadCount">
                    {{unreadCount > 99 ? '99+' : unreadCount}}
                </span>
            </ng-template>
        </div>
        <a *ngxPermissionsOnly="'PERMISSION_FOLLOW_PATIENTS'"
           class="d-flex justify-content-center align-items-center face cursor-pointer"
           [routerLink]="'/arsana-notes'">
            <fal i="comments" class="icon"></fal>
            <span *ngIf="unreadNoteCount" class="bubble icon-bubble badge-brand-3" [@pulse]="unreadNoteCount">
                {{unreadNoteCount > 99 ? '99+' : unreadNoteCount}}
            </span>
        </a>
    </nav>
</div>
