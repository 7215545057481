import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {LocalStorageService} from 'ngx-webstorage';
import {MenuItem, MenuItemDefinition} from './menu-item';
import {FAC_MENU_ITEMS, OTHER_MENU_ITEMS} from '../../definitions/definitions';
import {UserAuthService} from '../../@core/user-auth.service';
import {User} from '../../models/user';
import {FacilityChooserService} from '../../@core/facility-chooser.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationsService} from '../../@core/notifications.service';
import {pulseAnimationTrigger, stateTransition} from '../../@theme/animations';
import {UnsubscribeComponent} from '../../@core/fc-component';
import {filter, take, takeUntil} from 'rxjs/operators';
import {scrollIntoView} from '../../utils/dom.utils';
import {FollowingService} from '../../@core/following.service';

function menuItemFactory(localStorage: LocalStorageService, localStorageKey: string, menuItems, parent?: MenuItem): MenuItem[] {
    const items: MenuItem[] = [];
    for (const item of menuItems) {
        const menuItem: MenuItem = new MenuItem(localStorage, localStorageKey);
        for (const prop in item) {
            if (prop === 'children') {
                const children = menuItemFactory(localStorage, localStorageKey, item.children, menuItem);
                menuItem.children = children;
            } else {
                menuItem[prop] = item[prop];
            }
        }
        if (parent) menuItem.parent = parent;
        if (localStorage && localStorage.retrieve(localStorageKey) && localStorage.retrieve(localStorageKey).indexOf(menuItem.name) > -1) menuItem.open = true;
        items.push(menuItem);
    }
    return items;
}

@Component({
    selector: 'app-sidebar',
    styleUrls: ['./sidebar.component.scss'],
    templateUrl: './sidebar.component.html',
    animations: [
        trigger('menuOpen', [
            state('false', style({
                height: 0, padding: 0, margin: 0, overflow: 'hidden',
            })),
            transition('void => *', animate('0ms')),
            transition('* => *', animate(stateTransition)),
        ]),
        trigger('menuPassive', [
            state('true', style({
                opacity: '.4',
            })),
            transition('void => *', animate('0ms')),
            transition('* => *', animate(stateTransition)),
        ]),
        trigger('menuAccented', [
            state('true', style({
                boxShadow: '0 0 16px rgba(0, 0, 0, 1)',
                background: 'rgba(255, 255, 255, .05)',
            })),
            transition('void => *', animate('0ms')),
            transition('* => *', animate(stateTransition)),
        ]),
        pulseAnimationTrigger,
    ],
})
export class SidebarComponent extends UnsubscribeComponent implements OnInit {
    user: User;
    hasMultipleFacilities: boolean;
    facMenuItems: MenuItem[];
    otherMenuItems: MenuItem[];
    activeMenuSection: MenuItem[];
    userActions = {
        open: false,
    };
    unreadCount: number;
    unreadNoteCount: number;

    @Output() userClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() searchClick: EventEmitter<void> = new EventEmitter<void>();

    constructor(private router: Router,
                private route: ActivatedRoute,
                private localStorage: LocalStorageService,
                private userAuth: UserAuthService,
                public fc: FacilityChooserService,
                private modalService: NgbModal,
                private notiService: NotificationsService,
                private followingService: FollowingService) {
        super();
        this.userAuth.user.pipe(take(1)).subscribe(u => {
            this.user = u;
            this.processMenuItems();
            this.hasMultipleFacilities = this.fc.options.filter(x => !x.from_customer_only).length > 1;
            router.events.pipe(takeUntil(this.destroy$), filter(e => e instanceof NavigationEnd)).subscribe(() => this.setParentState());
            if (this.user.permissions.includes('PERMISSION_FOLLOW_PATIENTS')) {
                this.followingService.getAssignedTaskCount('unresolved')
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(unreadNotes => this.unreadNoteCount = unreadNotes);
            }
        });
    }

    private processMenuItems() {
        [{key: 'facMenuItems', list: FAC_MENU_ITEMS}, {key: 'otherMenuItems', list: OTHER_MENU_ITEMS}].forEach(group => {
            this[group.key] = menuItemFactory(this.localStorage, `${group.key}Open`, group.list).filter(m => SidebarComponent.menuItemIsAvailable(m, this.user));
            this[group.key].forEach(m => {
                if (m.children) m.children = m.children.filter(mm => SidebarComponent.menuItemIsAvailable(mm, this.user));
            });
            this[group.key] = this[group.key].filter(m => m.link || (m.children && m.children.length > 0));
        });
        this.setParentState();
    }

    private countMatchingItems(aArr: string[], bArr: string[]): number {
        const i = aArr.findIndex((fragment, i) => fragment != bArr[i]);
        return i > -1 ? i : aArr.length;
    }

    private findActiveMenuItem(): MenuItem {
        const urlFragments = this.router.url.split('/').slice(1);
        let bestMatch = null;
        let mostFragments = 0;

        for (const parent of [...this.facMenuItems, ...this.otherMenuItems]) {
            for (const item of [parent, ...(parent.children || [])]) {
                if (item.link) {
                    if (item.link === this.router.url) return item;

                    const matching = this.countMatchingItems(item.link.split('/').slice(1), urlFragments);
                    if (matching > mostFragments) {
                        bestMatch = item;
                        mostFragments = matching;
                    }
                }
            }
        }

        return bestMatch || null;
    }

    private setParentState() {
        this.otherMenuItems.forEach(x => x.toggleOpen('close'));
        const active = this.findActiveMenuItem();
        if (active) {
            if (active.parent) active.parent.toggleOpen('open');
            this.activeMenuSection = [this.facMenuItems, this.otherMenuItems].find(menuItems => menuItems.includes(active.parent || active));
            scrollIntoView('.side-nav .active', 'center');
        }
        let route = this.route;
        while (route.firstChild) route = route.firstChild;
    }

    ngOnInit() {
        this.notiService.unreadCount.pipe(takeUntil(this.destroy$)).subscribe(x => this.unreadCount = x);
    }

    static menuItemIsAvailable(menuItem: MenuItemDefinition, user: User): boolean {
        if ((menuItem.roles || menuItem.permissions) && user && user.id) {
            if (menuItem.roles && (menuItem.roles.includes('ANY') || user.roles.some(r => menuItem.roles.includes(r)))) return true;
            if (menuItem.permissions && (menuItem.permissions.includes('ANY') ||
               (user.permissions.some(p => menuItem.permissions.includes(p) &&
                    (!menuItem.andPermissions || user.permissions.some(p => menuItem.andPermissions.includes(p))))))) {
                return !menuItem.excludePermissions || !user.permissions.some(p => menuItem.excludePermissions.includes(p));
            }
        } else if (menuItem.children) {
            return menuItem.children.some(a => SidebarComponent.menuItemIsAvailable(a, user));
        }
        return false;
    }

    onUserClick() {
        this.userClick.emit();
    }

    openSearchDashboard() {
        this.searchClick.emit();
    }
}
