<div class="facility-select" (click)="toggle($event)" [class.open]="open" [@selectPulse]="selected">
    <fad i="building" class="icon"></fad>
    <div class="caption" *ngIf="selected" [@selectCaptionPulse]="selected">
        <div class="label" *ngIf="selected.organization">{{selected.organization.name}}</div>
        <div class="name">{{selected.name}}</div>
    </div>
    <fas i="chevron-down" class="chevron"></fas>
</div>
<div class="facility-select-options-wrapper" data-test-id="facility-select-options-wrapper">
    <div class="options card" (clickOutside)="close()" *ngIf="open" [@popFromLeft]>
        <div class="section-head p-4 m-0" [@popFromLeftInner]>
            <h3 class="title text-primary" style="opacity: 1">Select Facility</h3>
        </div>
        <div class="form-group facility-select-input-wrapper">
            <input id="facility-search-input"
                   [(ngModel)]="facilitySearchString"
                   (ngModelChange)="onFacilitySearchChange($event)"
                   type="text"
                   class="form-control"
                   placeholder="Search for facilities...">
        </div>
        <div class="options-inner">
            <div class="p-2" style="overflow-y: auto" [@popFromLeftInner]>
                <div class="nav flex-column nav-pills">
                    <ng-container *ngIf="options.length; else noMatch">
                        <ng-container *ngFor="let o of options; let i = index">
                            <div *ngIf="o.facility?.is_inactive && (i === 0 || !options[i-1].facility?.is_inactive); else orgHeadTmp"
                                 class="section-head m-0 p-3">
                                <h3 class="title">Inactive Facilities</h3>
                            </div>
                            <ng-template #orgHeadTmp>
                                <div *ngIf="o.organization && (i === 0 || o.organization != options[i-1].organization)"
                                     class="section-head m-0 p-3">
                                    <h3 class="title">{{o?.organization?.name}}</h3>
                                </div>
                            </ng-template>
                            <div class="d-flex align-items-center">
                                <div (click)="selectFacility(o)"
                                     [class.active]="selected.name == o.name"
                                     [class.keyboard-selected]="o.keyboardSelected"
                                     [class.inactive]="o.facility?.is_inactive"
                                     class="nav-link flex-grow-1 cursor-pointer">
                                    <span class="caption">{{o.name}}</span>
                                </div>
                                <ng-container *ngxPermissionsOnly="'PERMISSION_WRITE_ORGANIZATION'">
                                    <ng-container *ngIf="o.organization">
                                        <app-action-icon *ngIf="o.facility"
                                                         icon="pencil"
                                                         size="sm"
                                                         routerLink="/admin/organizations/facilities/{{o.facility.id}}"
                                                         (click)="close()">
                                        </app-action-icon>
                                        <app-action-icon *ngIf="!o.facility"
                                                         icon="pencil"
                                                         size="sm"
                                                         routerLink="/admin/organizations/{{o.organization.id}}"
                                                         (click)="close()">
                                        </app-action-icon>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <app-tail class="tail" direction="left"></app-tail>
    </div>
</div>

<ng-template #noMatch>
    <div class="text-muted section-head m-0 p-3">No facilities match the search.</div>
</ng-template>
