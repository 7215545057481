import {Component, HostListener, OnInit} from '@angular/core';
import {User} from '../../../models/user';
import {UserAuthService} from '../../../@core/user-auth.service';
import {FacilityChooserService, OrganizationFacilityDetails} from '../../../@core/facility-chooser.service';
import {UnsubscribeComponent} from '../../../@core/fc-component';
import {takeUntil} from 'rxjs/operators';
import {OrganizationSerializer} from '../../../@core/api.service';
import {longTransition, selectCaptionPulseAnimationTrigger, selectPulseAnimationTrigger, stateTransition} from '../../../@theme/animations';
import {animate, animateChild, group, query, style, transition, trigger} from '@angular/animations';
import {OverlayService} from '../../../@core/overlay.service';
import {scrollIntoView} from '../../../utils/dom.utils';

interface OrganizationFacilityDetailsViewModel extends OrganizationFacilityDetails {
    keyboardSelected: boolean;
}

@Component({
    selector: 'app-facility-select',
    templateUrl: './facility-select.component.html',
    styleUrls: ['./facility-select.component.scss'],
    animations: [
        trigger('popFromLeft', [
            transition('void => *', [
                style({transform: 'translateX(-16px)', opacity: '0'}),
                group([
                    query('@*', animateChild(), {optional: true}),
                    animate(longTransition),
                ]),
            ]),
            transition('* => void', [
                group([
                    query('@*', animateChild(), {optional: true}),
                    animate(stateTransition, style({transform: 'translateX(-16px)', opacity: '0'})),
                ]),
            ]),
        ]),
        trigger('popFromLeftInner', [
            transition('void => *', [
                style({transform: 'translateX(24px)'}),
                animate(longTransition),
            ]),
            transition('* => void', [
                animate(longTransition, style({transform: 'translateX(24px)'})),
            ]),
        ]),
        selectPulseAnimationTrigger,
        selectCaptionPulseAnimationTrigger,
    ],
})
export class FacilitySelectComponent extends UnsubscribeComponent implements OnInit {
    private _user: User;
    organizations: OrganizationSerializer[];
    selected: OrganizationFacilityDetails;
    originalOptions: OrganizationFacilityDetailsViewModel[];
    options: OrganizationFacilityDetailsViewModel[];
    open = false;
    facilitySearchString: string;

    @HostListener('document:keydown', ['$event'])
    keyboardNavigation(keyDownEvent: KeyboardEvent) {
        if (!this.open) return;

        const keyboardSelectedOptionIndex = this.options.findIndex(option => option.keyboardSelected);

        switch (keyDownEvent.key) {
            case 'ArrowUp':
                keyDownEvent.preventDefault();
                this.clearSelection();
                if (keyboardSelectedOptionIndex === -1) {
                    this.options[this.options.length - 1].keyboardSelected = true;
                    this.scrollSelectionIntoView();
                    return;
                }
                if (!this.options[keyboardSelectedOptionIndex - 1]) return;
                this.options[keyboardSelectedOptionIndex - 1].keyboardSelected = true;
                this.scrollSelectionIntoView();
                return;
            case 'ArrowDown':
                keyDownEvent.preventDefault();
                this.clearSelection();
                if (keyboardSelectedOptionIndex === -1) {
                    this.scrollSelectionIntoView();
                    this.options[0].keyboardSelected = true;
                    return;
                }
                if (!this.options[keyboardSelectedOptionIndex + 1]) return;
                this.options[keyboardSelectedOptionIndex + 1].keyboardSelected = true;
                this.scrollSelectionIntoView();
                return;
            case 'Enter':
                keyDownEvent.preventDefault();
                if (!this.options[keyboardSelectedOptionIndex]) return;
                return this.selectFacility(this.options[keyboardSelectedOptionIndex]);
            case 'Escape':
                return this.fc.openSelect$.next(false);
        }
    }

    constructor(private userAuth: UserAuthService, private fc: FacilityChooserService, private overlayService: OverlayService) {
        super();

        this.fc.getSilently().pipe(takeUntil(this.destroy$)).subscribe(() => this.selected = this.fc.selectedDetails);

        this.userAuth.user.pipe(takeUntil(this.destroy$)).subscribe(u => {
            if (!u) this.close();

            this._user = u;
            this.organizations = u ? u.organizations : [];
            this.originalOptions = u ? this.fc.options.filter(x => !x.from_customer_only).map(option => ({...option, keyboardSelected: false})) : [];
            this.options = this.originalOptions.map(x => ({...x}));
        });
    }

    ngOnInit() {
        moveDOMNodeToDocumentEnd('.facility-select-options-wrapper');
        this.fc.openSelect$.subscribe((open?: boolean) => {
            this.open = !!open;
            this.handleInput(open);
        });
    }

    selectFacility(option: OrganizationFacilityDetails) {
        this.fc.setSelectedFacility(option.value);
        this.close();
        this.overlayService.emit(null);
    }

    toggle(event) {
        event.stopPropagation();
        this.open = !this.open;
        this.clearSelection();
        this.handleInput(this.open);
    }

    close() {
        this.open = false;
        this.clearSelection();
        this.handleInput(this.open);
    }

    scrollSelectionIntoView() {
        scrollIntoView('.keyboard-selected');
    }

    clearSelection() {
        this.options.forEach(x => x.keyboardSelected = false);
    }

    onFacilitySearchChange(searchString) {
        this.clearSelection();
        this.scrollSelectionIntoView();
        if (!searchString) {
            this.options = this.originalOptions.map(x => ({...x}));
            this.options[0].keyboardSelected = true;
            return;
        }

        this.options = this.originalOptions
            .map(x => ({...x}))
            .filter(option => [option.name, option.organization?.name].some(x => x?.toLowerCase().includes(searchString.toLowerCase())));
        if (this.options[0]) this.options[0].keyboardSelected = true;
    }

    handleInput(open: boolean) {
        if (!this._user) return;

        this.facilitySearchString = null;
        if (open) {
            setTimeout(() => {
                const inputEl = document.getElementById('facility-search-input');
                if (inputEl) inputEl.focus();
                const activeEl = document.querySelector('.options-inner .active');
                if (activeEl) activeEl.scrollIntoView({block: 'center'});
            });
            this.clearSelection();
            const selectedFacility = this.options.find(option => this.selected.name === option.name) || this.options[0];
            selectedFacility.keyboardSelected = true;
            this.options = this.options.map(x => ({...x}));
        } else {
            const facilitySearchInput = (document.getElementById('facility-search-input') as HTMLInputElement);
            if (facilitySearchInput) facilitySearchInput.value = '';
            this.options = this.originalOptions;
        }
    }
}

export function moveDOMNodeToDocumentEnd(selectors: string) {
    const node = document.querySelector(selectors);
    const bodyNode = document.querySelector('body');
    if (node && bodyNode) bodyNode.appendChild(node);
}
