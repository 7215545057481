import {LocalStorageService} from 'ngx-webstorage';
import {Params} from '@angular/router';

export class MenuItemDefinition {
    name: string;
    link?: string;
    icon?: string;
    parent?: MenuItemDefinition;
    children?: MenuItemDefinition[];
    roles?: string[];
    permissions?: string[];
    andPermissions?: string[];
    excludePermissions?: string[];
    keywords?: string[];
    exactMatch?: boolean; // routerLinkActive directive 'exact' option
    testId?: string;
    queryParams?: Params;
}

export class MenuItem extends MenuItemDefinition {
    name: string;
    link?: string;
    icon?: string;
    parent?: MenuItem;
    children?: MenuItem[];
    open = false;
    roles?: string[];
    permissions?: string[];
    andPermissions?: string[];
    excludePermissions?: string[];
    keywords?: string[];
    exactMatch?: boolean; // routerLinkActive directive 'exact' option
    private readonly _localStorageKey: string;

    constructor(private localStorage: LocalStorageService,
                localStorageKey: string,
                parent?: MenuItem) {
        super();
        this._localStorageKey = localStorageKey;
        this.parent = parent;
    }

    toggleOpen(action?: string) {
        if (this.children) {
            switch (action) {
                case 'open':
                    this.open = true;
                    break;
                case 'close':
                    this.open = false;
                    break;
                default:
                    this.open = !this.open;
            }
            const a = this.localStorage.retrieve(this._localStorageKey);
            const menuItemsOpen: string[] = a ? a : [];
            const i = menuItemsOpen.indexOf(this.name);
            if (this.open && i < 0) {
                menuItemsOpen.push(this.name);
            } else if (!this.open && i > -1) {
                menuItemsOpen.splice(i, 1);
            }
            this.localStorage.store(this._localStorageKey, menuItemsOpen);
        }
    }
}
